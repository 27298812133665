import React from "react"
import { Text, StyleSheet, TextStyle } from "react-native"
import { fontSizes, space } from "../theme"

interface Props {
  style?: TextStyle
  children: React.ReactNode
}

export const Heading = (props: Props) => (
  <Text {...props} style={[props.style, styles.heading]} />
)

export const Body = (props: Props) => (
  <Text {...props} style={[props.style, styles.body]} />
)

export const Indented = (props: Props) => (
  <Text {...props} style={[props.style, styles.indented]} />
)

const styles = StyleSheet.create({
  heading: {
    fontSize: fontSizes.large,
    marginBottom: space[3],
  } as TextStyle,
  body: {
    fontSize: fontSizes.normal,
    marginBottom: space[3],
  } as TextStyle,
  indented: {
    fontSize: fontSizes.normal,
    marginBottom: space[3],
    marginLeft: space[3],
  } as TextStyle,
})
