import * as React from "react"
import { Component } from "react"
import { QuestionAnswer } from "./components/QuestionAnswer"
import { Container } from "./components/Container"
import ResultSummary from "./components/ResultSummary"
import { AppState, initialQuestionsState } from "./AppState"
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"
import Landing from "./components/Landing"
import { Body } from "./components/Text"
import Ignored from "./components/Ignored"

interface Props {}

const LOCALSTORAGE_KEY = "civicstest"

class App extends Component<Props, AppState> {
  state = {
    ...initialQuestionsState([]),
    ignoredQuestions: [],
  } as AppState

  componentDidMount() {
    document.title = "Civics Test"
    const persistedState = localStorage.getItem(LOCALSTORAGE_KEY)
    if (persistedState) {
      this.setState(JSON.parse(persistedState))
    }
  }

  componentDidUpdate() {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(this.state))
  }

  render() {
    const { questions } = this.state
    const lastCompletedQuestion = this.state.questions.findIndex(
      q => q.answer === ""
    )

    return (
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            render={props => (
              <Landing
                onStart={() => {
                  this.setState(
                    initialQuestionsState(this.state.ignoredQuestions)
                  )
                  props.history.push(`/questions/1`)
                }}
                canResume={lastCompletedQuestion > 0}
                onResume={() => {
                  props.history.push(`/questions/${lastCompletedQuestion + 1}`)
                }}
              />
            )}
          />

          <Route
            path="/questions/:questionId"
            exact
            render={props => {
              const position = parseInt(props.match.params.questionId, 10)
              const isLastQuestion = position === questions.length
              return (
                <QuestionAnswer
                  position={position}
                  total={questions.length}
                  question={questions[position - 1]}
                  onChangeAnswer={({ answer, isCorrect }) => {
                    this.setState(state => ({
                      questions: state.questions.map((question, i) => {
                        if (i === position - 1) {
                          return {
                            ...question,
                            answer,
                            isCorrect,
                          }
                        }
                        return question
                      }),
                    }))
                  }}
                  onNextQuestion={() => {
                    if (isLastQuestion) {
                      props.history.push(`/results`)
                    } else {
                      props.history.push(`/questions/${position + 1}`)
                    }
                  }}
                  onBack={() => {
                    if (position > 1) {
                      props.history.push(`/questions/${position - 1}`)
                    } else {
                      props.history.push(`/`)
                    }
                  }}
                />
              )
            }}
          />

          <Route
            path="/results"
            render={props => (
              <ResultSummary
                ignoredQuestions={this.state.ignoredQuestions}
                questions={questions}
                onRestart={() => {
                  this.setState(
                    initialQuestionsState(this.state.ignoredQuestions)
                  )
                  props.history.push("/questions/1")
                }}
                onIgnoreQuestion={questionPosition =>
                  this.setState(state => ({
                    ignoredQuestions: Array.from(
                      new Set([...state.ignoredQuestions, questionPosition])
                    ),
                  }))
                }
              />
            )}
          />

          <Route
            path="/ignored"
            render={props => (
              <Ignored
                ignoredQuestions={this.state.ignoredQuestions}
                onUnignore={questionId => {
                  this.setState(state => ({
                    ignoredQuestions: state.ignoredQuestions.filter(
                      id => id !== questionId
                    ),
                  }))
                }}
              />
            )}
          />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    )
  }
}

const NoMatch = () => (
  <Container>
    <Body>
      404! Page not found. <Link to="/">Go home?</Link>
    </Body>
  </Container>
)

/**
 * routes:
 *
 * /
 * /results
 * /settings
 *
 */

export default App
