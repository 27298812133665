import React from "react"
import { Container } from "./Container"
import { Heading, Body, Indented } from "./Text"
import { View, Button } from "react-native"
import { Question } from "../AppState"
import isCorrect from "../util/isCorrect"
import { isBlank } from "../util/isBlank"
import { colors, space } from "../theme"

const isQuestionCorrect = (question: Question) => {
  if (question.isCorrect !== null) return question.isCorrect
  if (isCorrect(question.answer, question.question.answers)) {
    return true
  }
  return null
}

export const QuestionResult = ({ question }: { question: Question }) => {
  const color = (correct => {
    switch (correct) {
      case null:
        return undefined
      case true:
        return colors.success
      case false:
        return colors.warning
    }
  })(isQuestionCorrect(question))

  return (
    <>
      <View>
        <Body>Provided:</Body>
        <Indented style={{ color }}>
          {isBlank(question.answer) ? "(None)" : question.answer}
        </Indented>
      </View>

      <View>
        <Body>Actual:</Body>
        {question.question.answers.map((answer, i) => (
          <Indented key={i}>{answer}</Indented>
        ))}
      </View>
    </>
  )
}

const ResultSummary = ({
  questions,
  onRestart,
  onIgnoreQuestion,
  ignoredQuestions,
}: {
  questions: Question[]
  onRestart: () => void
  onIgnoreQuestion: (questionPosition: number) => void

  ignoredQuestions: number[]
}) => (
  <Container>
    <Heading>Summary</Heading>
    <Body>
      You scored {questions.filter(({ isCorrect }) => isCorrect).length}/
      {questions.length}
    </Body>
    <Heading>Questions</Heading>
    <View>
      {questions.map((questionAnswer, i) => (
        <View key={i} style={{ marginBottom: space[5] }}>
          <View style={{ flexDirection: "row" }}>
            <Heading style={{ paddingRight: space[1] }}>{i + 1}.</Heading>
            <Heading>{questionAnswer.question.question}</Heading>
          </View>
          <View style={{ paddingLeft: space[4] }}>
            <QuestionResult question={questionAnswer} />
          </View>
          <Button
            onPress={() => onIgnoreQuestion(questionAnswer.question.questionIndex)}
            title="Ignore Question"
            color={colors.black}
            disabled={ignoredQuestions.includes(questionAnswer.question.questionIndex)}
          />
        </View>
      ))}
    </View>
    <Button onPress={() => onRestart()} title="Restart" />
  </Container>
)

export default ResultSummary
