// approach a: of, every combination of actual, >= 1 matches
// approach b: provided tests against every actual, but no more
const isCorrect = (provided: string, actual: string[]) => {
  return normalize(provided) === normalize(actual.join(", "))
}
export default isCorrect

export const normalize = (text: string) =>
  text
    .toLowerCase()
    .replace(/the\ /g, "")
    .replace(/and\ /g, "")
    .replace(/[^a-z0-9]/g, "")