// https://github.com/ReactTraining/react-router/issues/5437
import React from "react"
import { createLocation, LocationDescriptor } from "history"
import {
  Text,
  GestureResponderEvent,
  TextProps,
  Linking,
  TouchableOpacity,
} from "react-native"

// @ts-ignore
export const isModifiedEvent = event =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

export interface LinkProps extends TextProps {
  onClick?: (e: GestureResponderEvent) => void
  target?: string
  replace?: boolean
  to: LocationDescriptor
  innerRef?: (ref: Text) => void
}

export class Link extends React.Component<LinkProps> {
  static defaultProps = {
    replace: false,
  }

  static contextTypes = {
    router: () => null,
  }

  // @ts-ignore
  handleClick = event => {
    if (this.props.onClick) this.props.onClick(event)

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault()

      const { history } = this.context.router
      const { replace, to } = this.props

      if (replace) {
        history.replace(to)
      } else {
        history.push(to)
      }
    }
  }

  render() {
    const { replace, to, innerRef, ...props } = this.props

    if (!this.context.router)
      throw "You should not use <Link> outside a <Router>"

    const { history } = this.context.router
    const location =
      typeof to === "string"
        ? createLocation(to, null, undefined, history.location)
        : to

    const href = history.createHref(location)
    return (
      // <a {...props} onClick={this.handleClick} href={href} ref={innerRef} />
      // @ts-ignore
      <Text
        {...props}
        onPress={this.handleClick}
        href={href}
        accessibilityRole="link"
        ref={innerRef}
      />
    )
  }
}

export class BoxLink extends React.Component<LinkProps> {
  static defaultProps = {
    replace: false,
  }

  static contextTypes = {
    router: () => null,
  }

  // @ts-ignore
  handleClick = event => {
    if (this.props.onClick) this.props.onClick(event)

    if (
      !event.defaultPrevented && // onClick prevented default
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault()

      const { history } = this.context.router
      const { replace, to } = this.props

      if (replace) {
        history.replace(to)
      } else {
        history.push(to)
      }
    }
  }

  render() {
    const { replace, to, innerRef, ...props } = this.props

    if (!this.context.router)
      throw "You should not use <Link> outside a <Router>"

    const { history } = this.context.router
    const location =
      typeof to === "string"
        ? createLocation(to, null, undefined, history.location)
        : to

    const href = history.createHref(location)
    return (
      // <a {...props} onClick={this.handleClick} href={href} ref={innerRef} />
      <TouchableOpacity
        {...props}
        onPress={this.handleClick}
        href={href}
        accessibilityRole="link"
        // @ts-ignore
        ref={innerRef}
        style={{ flex: 1 }}
      />
    )
  }
}
