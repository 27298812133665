export const space = [0, 4, 8, 16, 32, 64];

export const fontSizes = {
  large: 20,
  normal: 16,
}

export const colors = {
  primary: "#000000",
  success: "#88CC80",
  warning: "#ED6A5E",
  // background: "#F8F4A6",
  background: "#FFFFFF",
  black: "#000000",
}
