import React from "react"
import {
  TextInput as RNTextInput,
  StyleSheet,
  TextStyle,
  TextInputProps,
} from "react-native"
import { space } from "../theme"

export const TextInput = React.forwardRef((props: TextInputProps, ref: React.Ref<RNTextInput>) => (
  <RNTextInput ref={ref} {...props} style={styles.input} />
))

const styles = StyleSheet.create({
  input: {
    borderColor: "#333333",
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 2,
    marginBottom: space[2],
    fontSize: 16,
    padding: space[2],
    flex: 1,
  } as TextStyle,
})
