import { USCISQuestion, getRandomQuestions } from "./data/questions"

// TODO: minimumMatches, e.g. 1, 3, null = all
export interface Question {
  question: USCISQuestion
  answer: string
  isCorrect: boolean | null
}

export interface AppState {
  questions: Question[]
  ignoredQuestions: number[]
}

export const initialQuestionsState = (ignoredQuestions: number[]) => ({
  questions: getRandomQuestions(10, ignoredQuestions).map(question => ({
    question,
    answer: "",
    isCorrect: null as null | boolean,
  })),
})
