import React from "react"
import { Container } from "./Container"
import { Body, Heading } from "./Text"
import { questions } from "../data/questions"
import { View, Button } from "react-native"
import { space } from "../theme"

interface Props {
  ignoredQuestions: number[]
  onUnignore: (questionId: number) => void
}

const Ignored = ({ ignoredQuestions, onUnignore }: Props) => (
  <Container>
    <Heading>Ignored Questions</Heading>
    {ignoredQuestions.length === 0 ? (
      <Body>No ignored questions</Body>
    ) : (
      ignoredQuestions.map((questionId, i) => {
        const question = questions[questionId]
        return (
          <View key={i} style={{ marginBottom: space[4] }}>
            <Body>{question.question}</Body>
            <Button onPress={() => onUnignore(questionId)} title="Unignore" />
          </View>
        )
      })
    )}
  </Container>
)

export default Ignored
