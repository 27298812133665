import React from "react"
import { Container } from "./Container"
import { Body } from "./Text"
import { Button, View } from "react-native"
import { space, colors } from "../theme"

interface Props {
  onStart: () => void
  canResume: boolean
  onResume: () => void
}

const Landing = (props: Props) => (
  <Container>
    <Body>Civics Practice</Body>
    {props.canResume && (
      <View style={{ marginBottom: space[2] }}>
        <Button onPress={props.onResume} title="Resume" />
      </View>
    )}
    <View style={{ marginBottom: space[2] }}>
      <Button
        onPress={props.onStart}
        title={props.canResume ? "Restart" : "Begin"}
        color={colors.black}
      />
    </View>
  </Container>
)

export default Landing
