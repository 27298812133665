import React, { Component } from "react"
import { Button, View, TextInput as RNTextInput } from "react-native"
import { Heading, Body } from "./Text"
import { TextInput } from "./Input"
import { Question } from "../AppState"
import { space, colors } from "../theme"
import { QuestionResult } from "./ResultSummary"
import { Container } from "./Container"

interface QProps {
  position: number
  total: number
  onChangeAnswer: (
    { answer, isCorrect }: { answer: string; isCorrect: boolean | null }
  ) => void
  onNextQuestion: () => void
  onBack: () => void
  question: Question
}

export class QuestionAnswer extends Component<QProps> {
  state = {
    isAnswered: false,
  }

  input: React.RefObject<RNTextInput> = React.createRef()

  componentDidUpdate(prevProps: QProps) {
    if (prevProps.position !== this.props.position) {
      this.setState({ isAnswered: false })
    }

    requestAnimationFrame(() => {
      this.input.current && this.input.current.focus()
    })
  }

  render() {
    const { isAnswered } = this.state
    const { question, position, total, onChangeAnswer, onBack } = this.props

    const handleSubmitAnswer = () => {
      this.setState({ isAnswered: true })
    }

    return (
      <Container>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Heading>
            Question {position}/{total}
          </Heading>
          <View>
            <Button title="Back" onPress={onBack} color="#000000" />
          </View>
        </View>

        <Body>{question.question.question}</Body>

        <TextInput
          value={question.answer}
          onChangeText={text =>
            onChangeAnswer({ answer: text, isCorrect: question.isCorrect })
          }
          onSubmitEditing={handleSubmitAnswer}
          editable={!isAnswered}
          ref={this.input}
        />

        {isAnswered ? (
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1, marginRight: space[1] }}>
              <Button
                title="Incorrect"
                onPress={() => {
                  this.props.onChangeAnswer({
                    answer: question.answer,
                    isCorrect: false,
                  })
                  this.props.onNextQuestion()
                }}
                color={colors.warning}
              />
            </View>
            <View style={{ flex: 1, marginLeft: space[1] }}>
              <Button
                title="Correct"
                onPress={() => {
                  this.props.onChangeAnswer({
                    answer: question.answer,
                    isCorrect: true,
                  })
                  this.props.onNextQuestion()
                }}
                color={colors.success}
              />
            </View>
          </View>
        ) : (
          <Button
            title="Submit"
            onPress={handleSubmitAnswer}
            color={colors.primary}
          />
        )}

        {isAnswered ? (
          <View style={{ marginTop: space[3] }}>
            <QuestionResult question={question} />
          </View>
        ) : null}
      </Container>
    )
  }
}
