import React from "react"
import { space, colors } from "../theme"
import { View, StyleSheet, ViewStyle, ScrollView } from "react-native"
import { Link } from "./Link"
// import { Link } from "react-router-dom"

export const Container = ({ children }: { children: React.ReactNode }) => (
  <View style={styles.background} testID="Container">
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>{children}</View>
    </ScrollView>
    <View style={styles.navContainer}>
      <Nav />
    </View>
  </View>
)

const Nav = () => (
  <View style={styles.nav}>
    <Link to="/" style={styles.navLink}>
      Home
    </Link>
    <Link to="/ignored" style={styles.navLink}>
      Ignored Questions
    </Link>
  </View>
)

const linkStyle: React.CSSProperties = {
  padding: space[3],
}

const MAX_WIDTH = 600
const styles = StyleSheet.create({
  container: {
    paddingTop: space[3],
    paddingBottom: space[3],
    paddingLeft: space[3],
    paddingRight: space[3],
    maxWidth: MAX_WIDTH,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  } as ViewStyle,
  background: {
    backgroundColor: colors.background,
    flex: 1,
    height: "100%",
  } as ViewStyle,
  scrollView: { flex: 1 } as ViewStyle,
  navContainer: {
    backgroundColor: "#eeeeee",
  } as ViewStyle,
  nav: {
    maxWidth: MAX_WIDTH,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row",
  } as ViewStyle,
  navLink: {
    padding: space[3],
  } as ViewStyle,
})
