import data from "./questions.json"
import { sampleSize } from "lodash"

export interface USCISQuestion {
  question: string
  number: number
  answers: string[]
  section: string
  questionIndex: number
}

export interface Sections {
  [x: string]: string
}

export const questions = data.questions.map((q, i) => ({
  ...q,
  questionIndex: i,
})) as USCISQuestion[]

export const sections = data.sections as Sections

export const getRandomQuestions = (
  count: number,
  ignoredQuestions: number[]
) => {
  const legitQuestions = questions.filter(
    (_v, i) => !ignoredQuestions.includes(i)
  )
  return sampleSize(legitQuestions, count)
}
