import App from "./App"
import { AppRegistry } from "react-native"
import * as serviceWorker from './serviceWorker';


AppRegistry.registerComponent("App", () => App)

AppRegistry.runApplication("App", {
  rootTag: document.getElementById("root")
})

serviceWorker.register()
